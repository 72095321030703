<template>
  <div style="background-color: #fff">
    <Row>
      <Col
        span="5"
        style="padding: 2rem 2rem; text-align: left; padding-right: 1rem"
      >
        <span style="color: #5b5b5b; font-size: 1rem; padding-left: 1.75rem"
          >Filters</span
        >
        <Menu
          mode="vertical"
          theme="light"
          :active-name="menuActive"
          @on-select="menuSelect"
          ref="menus"
          width="auto"
          style="
            margin-right: 1.2rem;
            margin-top: 0.6rem;
            border-top: #e6e6e6 0.0625rem solid;
          "
        >
          <MenuItem name="All">
            <Icon size="17" type="md-notifications" />
            All
          </MenuItem>
          <MenuItem name="Vote">
            <Icon size="17" type="md-hand" />
            Vote
          </MenuItem>
          <MenuItem name="Open artifact">
            <Icon size="17" type="md-analytics" />
            Open artifact
          </MenuItem>
          <MenuItem name="Join request">
            <Icon size="17" type="md-person-add" />
            Join request
          </MenuItem>
          <MenuItem name="Extend request">
            <Icon size="17" type="md-add" />
            Extend request
          </MenuItem>
          <MenuItem name="Invest/Buy">
            <Icon size="17" type="md-cart" />
            Invest/Buy
          </MenuItem>
          <MenuItem name="Withdraw">
            <Icon size="17" type="logo-usd" />
            Withdraw
          </MenuItem>
          <MenuItem name="Grant Segment">
            <Icon size="17" type="md-archive" />
            Grant Segment
          </MenuItem>
        </Menu>
      </Col>
      <Col span="19" style="padding-right: 4rem">
        <div style="margin-top: 2rem; text-align: left">
          <Checkbox
            v-model="single"
            @on-change="checkBox"
            border
            style="font-size: 0.8rem; margin: 0 0.5rem"
            >Unread</Checkbox
          >
          <Input
            v-model="projectFilter"
            placeholder="Project name filter"
            style="width: 31.25rem; margin: 0 0.5rem"
          >
            <Icon type="ios-funnel" slot="prefix" />
          </Input>
          <Button style="margin: 0 0.5rem" @click="MarkAllasRead"
            >Mark all as read</Button
          >
        </div>
        <div
          v-if="message.length == 0"
          style="width: 100%; min-height: 25rem; text-align: center"
        >
          <span
            style="
              line-height: 25rem;
              font-size: 1.2rem;
              color: darkgray;
              width: 100%;
            "
            >All caught up!</span
          >
        </div>
        <div
          v-for="(item, i) in message"
          :class="item.classType"
          class="messageBox"
          @click="read(i)"
        >
          <div class="itemIcon">
            <Icon size="30" v-if="item.type == 'Vote'" type="md-hand" />
            <Icon
              size="30"
              v-if="item.type == 'Open artifact'"
              type="md-analytics"
            />
            <Icon
              size="30"
              v-if="item.type == 'Join request'"
              type="md-person-add"
            />
            <Icon
              size="30"
              v-if="item.type == 'Extend request'"
              type="md-add"
            />
            <Icon size="30" v-if="item.type == 'Invest/Buy'" type="md-cart" />
            <Icon size="30" v-if="item.type == 'Withdraw'" type="logo-usd" />
            <Icon
              size="30"
              v-if="item.type == 'share file'"
              type="md-archive"
            />
            <Icon
              size="30"
              v-if="item.type == 'grant segment'"
              type="md-archive"
            />
          </div>
          <div style="display: inline-block">
            <div class="itemType">
              <span>{{ item.type }}</span>
            </div>
            <div class="itemTitle">
              <span v-if="item.classType == 'hasRead'" style="color: #b1b1b1">{{
                item.description
              }}</span>
              <span v-if="item.classType != 'hasRead'">{{
                item.description
              }}</span>
            </div>
            <!-- <div class="itemProjectName">
							<a style="color: #8aafff;">{{item.projectName}}</a>
						</div> -->
            <div class="itemDate">
              <span>{{ item.date }}</span>
            </div>
          </div>
          <div
            style="display: inline-block; float: right; height: 4rem"
            v-if="item.type == 'Invest/Buy'"
          >
            <Button type="text" shape="circle" style="height: 4rem"
              ><Icon type="md-cloud-download" size="28"
            /></Button>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      single: "true",
      projectFilter: "",
      message: [],
      allMessage: [],
      menuActive: "All",
    };
  },
  methods: {
    menuSelect(val) {
      if (val == "All") {
        this.message = this.allMessage;
      } else {
        this.message = [];
        for (var i = 0; i < this.allMessage.length; i++) {
          if (this.allMessage[i].type == val) {
            this.message.push(this.allMessage[i]);
          }
        }
      }
    },
    checkBox(val) {
      if (val) {
        this.message = [];
        for (var i = 0; i < this.allMessage.length; i++) {
          if (this.allMessage[i].classType == "unRead") {
            this.message.push(this.allMessage[i]);
          }
        }
      } else {
        this.message = this.allMessage;
      }
    },
    MarkAllasRead() {
      for (var i = 0; i < this.allMessage.length; i++) {
        if (this.allMessage[i].classType == "unRead") {
          this.allMessage[i].classType = "hasRead";
        }
      }
      let Tself = this;
      Tself.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/message/readAll",
        data: {
          type: "all",
          userId: this.userId,
        },
      })
        .then(function (response) {
          Tself.$router.go(0);
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      this.message = this.allMessage;
    },
    read(i) {
      // if(this.message[i].classType == 'hasRead'){
      // 	return;
      // }
      this.message[i].classType = "hasRead";
      for (var j = 0; j < this.allMessage.length; j++) {
        if (this.message[i].id == this.allMessage[j].id) {
          this.allMessage[j].classType = "hasRead";
          break;
        }
      }
      let Tself = this;
      let user_id = Tself.$cookies.get("user_id");
      console.log(Tself.message[i].id + "!!");
      console.log(Tself.message[i].link + "!!");
      Tself.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/message/read",
        data: this.message[i].id,
      })
        .then(function (response) {
          if (Tself.message[i].link === "") {
            Tself.$axios({
              headers: {
                "Content-Type": "application/text",
              },
              method: "post",
              url: "/message/hasUnRead",
              data: user_id,
            })
              .then(function (response) {
                var data = response.data.data.items;
                if (!data) {
                  Tself.$router.go(0);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            if (
              Tself.message[i].type === "share file" ||
              Tself.message[i].type === "grant Segment"
            ) {
              let query = Tself.message[i].link.split("?")[1];
              Tself.$router.push(`checkGrantSegment?${query}`);
            } else {
              Tself.$router.push(Tself.message[i].link);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.$cookies.isKey("user_name")) {
      var Tself = this;
      this.userId = this.$cookies.get("user_id");
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/message/find",
        data: {
          query: "",
          type: "all",
          read: true,
          userId: this.userId,
        },
      })
        .then(function (response) {
          var items = response.data.data.items;
          for (var i = 0; i < items.length; i++) {
            if (items[i].type == "vote") {
              items[i].type = "Vote";
            } else if (items[i].type == "open") {
              items[i].type = "Open artifact";
            } else if (items[i].type == "join") {
              items[i].type = "Join request";
            } else if (items[i].type == "extend") {
              items[i].type = "Extend request";
            } else if (items[i].type == "invest") {
              items[i].type = "Invest/Buy";
            } else if (items[i].type == "withdraw") {
              items[i].type = "Withdraw";
            } else if (items[i].type == "Grant Segment") {
              items[i].type = "Grant Segment";
            }
          }
          Tself.allMessage = [];
          for (var i = 0; i < items.length; i++) {
            Tself.allMessage.push({
              id: items[i].id,
              type: items[i].type,
              classType: items[i].read ? "hasRead" : "unRead",
              // classType: 'unRead',
              description: items[i].content,
              date: items[i].time,
              link: items[i].link,
            });
          }
          Tself.message = Tself.allMessage;
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.$router.push("/Login");
    }
  },
};
</script>

<style>
.messageBox {
  text-align: left;
  padding: 1rem 2rem;
  border: #222222 0.0625rem solid;
  margin: 1rem 0;
  border-radius: 0.8rem;
}
.hasRead {
  color: #cecece !important;
  border-color: #cecece !important;
}
.itemIcon {
  float: left;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.itemType {
  font-size: 0.75rem;
  color: darkgray;
}
.itemTitle {
  max-width: 800px;
  font-size: 18px;
  max-width: 58.75remd;
}
.itemProjectName {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 0.75rem;
}
.itemDate {
  display: inline-block;
  font-size: 0.75rem;
  color: darkgray;
}
</style>
